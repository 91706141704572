import { useSelector, useDispatch } from 'react-redux';
import { expand, collapse } from 'reducers/sidebarReducer';
import './Collapse.css';


function Collapse(props) {
	const dispatch = useDispatch();
	const {
		buttonComponent,
		children,
		groupingId,
	} = props;

	const isCollapsed = useSelector((state) => !state.sidebar.expandedItems[groupingId]);

	let icon = <i className="bi bi-chevron-right" />;

	if (isCollapsed) {
		icon = <i className="bi bi-chevron-right" />;
	} else {
		icon = <i className="bi bi-chevron-down" />;
	}

	const toggleCollapse = () => {
		if (isCollapsed) {
			return dispatch(expand(groupingId));
		} else if (!isCollapsed) {
			return dispatch(collapse(groupingId));
		}
	}

	return (
		<div className="sidebar-collapse-container">
			<button className={`sidebar-collapse-button ${isCollapsed ? 'is-collapsed' : 'is-expanded'}`} onClick={toggleCollapse}>
				{buttonComponent}<span className="sidebar-collapse-icon">{icon}</span>
			</button>
			{!isCollapsed &&
				<div className="sidebar-collapse-content">
					{children}
				</div>
			}
		</div>
	);
}

export default Collapse;
