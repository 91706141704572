import { get } from 'data/services/apiService';
// import { API_URL } from 'data/services/apiConstants';

import { getEmailExportUrl } from 'data/services/exportService';


const EXPORT_EMAIL_REPORT_REQUEST = 'EXPORT_EMAIL_REPORT_REQUEST';
const EXPORT_EMAIL_REPORT_SUCCESS = 'EXPORT_EMAIL_REPORT_SUCCESS';
const EXPORT_EMAIL_REPORT_ERROR = 'EXPORT_EMAIL_REPORT_ERROR';


const initialState = {
	isSendingEmail: false,
};

export const exportEmailReport = (propertyId, dataSetArray, email) => (dispatch) => {
	let url = getEmailExportUrl(propertyId, dataSetArray, email);
	dispatch(emailExportRequest());
	return get({
		dispatch,
		url,
		onSuccess: result => dispatch(emailExportSuccess(result)),
		onError: error => dispatch(emailExportError(error)),
	});
};



export const emailExportRequest = () => ({
	type: EXPORT_EMAIL_REPORT_REQUEST,
	payload: {
		isSendingEmail: true,
	}
});

export const emailExportSuccess = () => ({
	type: EXPORT_EMAIL_REPORT_SUCCESS,
	payload: {
		isSendingEmail: false,
	}
});

export const emailExportError = () => ({
	type: EXPORT_EMAIL_REPORT_ERROR,
	payload: {
		isSendingEmail: false,
	}
});


const exportReducer = (state = initialState, action) => {
	switch (action.type) {
		case EXPORT_EMAIL_REPORT_REQUEST:
		case EXPORT_EMAIL_REPORT_SUCCESS:
		case EXPORT_EMAIL_REPORT_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default exportReducer;
