
import { 
	DATA_SET_OPTIONS,
	DATA_SET_VIOLATION_OPTIONS,
	DATA_SET_APPROVALS_OPTIONS
 } from 'pages/backendSearch/backendSearchConstants';

import Collapse from 'components/Collapse';

const DataSetGroup = ({ title, items, selectedDataSet, onSelect, initCollapsed }) => {
	return (
		<div>
			<Collapse title={title} initCollapsed={initCollapsed} >
				<div>
					{items.map((option) => (
						<label key={option.value} className={`data-set-radio-option ${selectedDataSet === option.value ? 'is-selected' : ''}`}>
							<input
								type="radio"
								name="dataSet"
								key={option.value}
								value={option.value}
								checked={selectedDataSet === option.value}
								onChange={onSelect}
							/>
							<span className="label-text">{option.label}</span>
						</label>
					))}
				</div>
			</Collapse>
		</div>
	);
}

export const DataSetSelectDesktop = ({ selectedDataSet, onSelect }) => {
	return (
		<div className="data-set-select">
			<h2>Select Data Set</h2>
			<div className="data-set-options-desktop">
				<DataSetGroup title="Approvals" items={DATA_SET_APPROVALS_OPTIONS} selectedDataSet={selectedDataSet} onSelect={onSelect} />
				<DataSetGroup title="Violations" items={DATA_SET_VIOLATION_OPTIONS} selectedDataSet={selectedDataSet} onSelect={onSelect} initCollapsed />
{/*				{DATA_SET_OPTIONS.map((option) => (
					<label key={option.value} className={`data-set-radio-option ${selectedDataSet === option.value ? 'is-selected' : ''}`}>
						<input
							type="radio"
							name="dataSet"
							key={option.value}
							value={option.value}
							checked={selectedDataSet === option.value}
							onChange={onSelect}
						/>
						<span className="label-text">{option.label}</span>
					</label>
				))}*/}
			</div>
		</div>
	);
}

export const DataSetSelectMobile = ({ selectedDataSet, onSelect }) => {
	return (
		<select 
			className="form-select" 
			placeholder="Select Data Set" 
			value={selectedDataSet} 
			onChange={onSelect}
			autoFocus
		>
			{DATA_SET_OPTIONS.map((option) => (
				<option key={option.value} value={option.value}>{option.label}</option>
			))}
		</select>
	);
}