import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { exportEmailReport } from 'reducers/exportReducer';
import { isValidEmail } from 'utils/validators';
import StandardModal from 'modals/StandardModal';

import Alert from 'view/components/Alert';
import Spinner from 'view/components/Spinner';

// import './ExportEmailReportModal.css';

const ExportEmailReportModal = ({ options }) => {
	const dispatch = useDispatch();
	const { propertyId, dataSetArray } = options;
	const [email, setEmail] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState('');
	const {
		isSendingEmail,
	} = useSelector(state => ({
		isSendingEmail: state.exportReducer.isSendingEmail,
	}));

	const onSend = () => {
		dispatch(exportEmailReport(propertyId, dataSetArray, email)).then((res) => {
			if (res?.type === "EXPORT_EMAIL_REPORT_SUCCESS") {
				setIsSuccess(true);
				setIsError(false);
			} else if (res?.type === "EXPORT_EMAIL_REPORT_ERROR") {
				setIsSuccess(false);
				setIsError(true);
			}
		});
		// let url = getEmailExportUrl(propertyId, dataSetArray, email);
		// console.log('url', url);
		// window.open(url, '_blank').focus();
	}


	const errorAlert = (
		<Alert color="danger">
			<p><strong>Oops, looks like something went wrong</strong></p>
			<div>Email report could not be sent</div>
		</Alert>
	);

	const successAlert = (
		<Alert color="success">
			<p><strong>Success!</strong></p>
			<div>Your report has been sent!</div>
		</Alert>
	);

	const instructions = (
		<div className="mb-3">
			Enter email address to receive selected report(s)
		</div>
	);

	let footerButtons = [
		<button key="excel" className="btn btn-primary" disabled={!isEmailValid || isSendingEmail} onClick={() => onSend()}>Send</button>
	];

	const onChangeEmail = (emailString) => {
		setEmail(emailString);
		setIsEmailValid(isValidEmail(emailString));
	}

	let modalContent = (
		<div>
			{!isSuccess && !isError && instructions}
			{isSuccess && successAlert}
			{isError && errorAlert}
			<div>
				<div className="form-group">
					<label>Email Address</label>
					<input
						type="email"
						placeholder="Email"
						className={`form-control email-input ${isEmailValid ? 'valid' : 'invalid'}`}
						required
						value={email}
						onChange={(ev) => onChangeEmail(ev.target.value)}
						autoFocus
					/>
				</div>
			</div>
		</div>
	);

	return (
		<StandardModal
			title="Email Report"
			size="md"
			closeOnOutsideClick
			footerButtons={footerButtons}
			disableConfirm={isSendingEmail || !email?.length || !isEmailValid}
			disableClose={isSendingEmail}
			closeLabel="Cancel"
		>
			{isSendingEmail ? <Spinner /> : modalContent}
		</StandardModal>
	);
};

export default ExportEmailReportModal;
