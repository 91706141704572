import { post } from 'data/services/apiService';
import { API_URL } from 'data/services/apiConstants';

export const MISSING_PROPERTY_REQUEST = 'MISSING_PROPERTY_REQUEST';
export const MISSING_PROPERTY_SUCCESS = 'MISSING_PROPERTY_SUCCESS';
export const MISSING_PROPERTY_ERROR = 'MISSING_PROPERTY_ERROR';


export const initialState = {
	isSubmitting: false,
	error: null,
};


export const submitMissingProperty = (params) => (dispatch, getState) => {
	let url = `${API_URL}/api/missing-property-reports`;
	
	let data = {
		name: params.name || '',
		email: params.email || '',
		address: params.address || '',
		comment: params.comment || '',
		type: params.type || '',
	}


	dispatch(missingPropertyRequest());
	return post({
		dispatch,
		url,
		data,
		onSuccess: (result) => {
			return dispatch(missingPropertyRequestSuccess(result))
		},
		onError: (error) => {
			return dispatch(missingPropertyRequestError(error))
		},
	});
};

export const missingPropertyRequest = () => ({
	type: MISSING_PROPERTY_REQUEST,
	payload: {
		isSubmitting: true,
	}
});

export const missingPropertyRequestSuccess = (data) => ({
	type: MISSING_PROPERTY_SUCCESS,
	payload: {
		isSubmitting: false,
		error: null,
	}
});

export const missingPropertyRequestError = (error) => ({
	type: MISSING_PROPERTY_ERROR,
	payload: {
		isSubmitting: false,
		error: error,
	}
});

const missingPropertyReducer = (state = initialState, action) => {
	switch (action.type) {
		case MISSING_PROPERTY_REQUEST:
		case MISSING_PROPERTY_SUCCESS:
		case MISSING_PROPERTY_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default missingPropertyReducer;
