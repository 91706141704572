import { get } from 'data/services/apiService';
import { API_URL } from 'data/services/apiConstants';

const GET_PROPERTY_LIST_REQUEST = 'GET_PROPERTY_LIST_REQUEST';
const GET_PROPERTY_LIST_SUCCESS = 'GET_PROPERTY_LIST_SUCCESS';
const GET_PROPERTY_LIST_ERROR = 'GET_PROPERTY_LIST_ERROR';


const initialState = {
	propertyList: null,
	isLoading: false,
	error: null,
};


export const getPropertyList = (userId, sort='address|asc') => (dispatch, getState) => {
	let state = getState();
	let url = `${API_URL}/api/portfolio/${userId}?sort=${sort}&per_page=${99999}`;
	dispatch(getPropertyListRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			return dispatch(getPropertyListSuccess(result))
		},
		onError: error => dispatch(getPropertyListError(error)),
	});
};

export const getPropertyListRequest = () => ({
	type: GET_PROPERTY_LIST_REQUEST,
	payload: {
		isLoading: true,
		propertyList: null,
	}
});

export const getPropertyListSuccess = (data) => ({
	type: GET_PROPERTY_LIST_SUCCESS,
	payload: {
		isLoading: false,
		propertyList: data,
		error: null,
	}
});

export const getPropertyListError = (error) => ({
	type: GET_PROPERTY_LIST_ERROR,
	payload: {
		isLoading: false,
		propertyList: null,
		error: error,
	}
});

const propertyListReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PROPERTY_LIST_REQUEST:
		case GET_PROPERTY_LIST_SUCCESS:
		case GET_PROPERTY_LIST_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default propertyListReducer;
