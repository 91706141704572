import { useSelector } from 'react-redux';

import dataTables from 'view/dataTables';
import { dataConfig } from 'data/dataConfig';
import { DATA_SOURCES, getAppRoute } from 'data/dataSourceConstants';

import { getLabel } from 'utils/dataSetLinkUtils';
import Badge from 'components/Badge';
import Collapse from 'components/Collapse';


import './DataSetsList.css';


function getGrouping(dataSet, config) {
	if (config.dataCategory === 'violation') {
		if (config.grouping) {
			return config.grouping;
		}
		return config.title;
	}
	return config.title;
}

export const buildDataSetItem = (dataSet, dataSource, propertyData) => {
	let config = dataTables[dataSet];
	if (config[dataSource]) {
		let openIssueCountField = dataConfig[dataSet]?.openIssueCountFields[dataSource];
		let openIssueCount = propertyData?.[openIssueCountField] || 0;
		let label = getLabel(config, dataSource);
		let dataSetRoute = getAppRoute(dataSet, dataSource);
		
		return {
			url: dataSetRoute,
			label,
			grouping: getGrouping(dataSet, config),
			config,
			dataSet,
			dataSource,
			openIssueCount,
			dataConfig: dataConfig[dataSet]
		};	
	}
}

const fdnySpecial = {
	fdnyViolations: true,
	fdnyInspections: true,
	fdnyPermits: true,
	fdnySummaries: true,
};

function getLinks(dataCategory, propertyData, fdnyOnly) {
	let items = [];
	Object.keys(dataTables).forEach((dataSet) => {
		let config = dataTables[dataSet];

		//data source sub-loop
		DATA_SOURCES.forEach((dataSource) => {
			if (config[dataSource]) {
				if (config.dataCategory === dataCategory) {

					if (fdnyOnly) {
						if (fdnySpecial[dataSet]) {
							items.push(buildDataSetItem(dataSet, dataSource, propertyData));	
						} 
					} else if (!fdnySpecial[dataSet]) {
						items.push(buildDataSetItem(dataSet, dataSource, propertyData));
					}

				}
			}
		});
	});
	return items;
}


function DataSetsList({ category, onSelectDataSet, selectedDataSet }) {
	let propertyData = useSelector(state => state.property.propertyData || {});
	let items = getLinks(category, propertyData);
	let fdnyArchiveItems = getLinks(category, propertyData, true);

	const isSelected = (item) => {
		return item?.dataSet === selectedDataSet?.dataSet && item?.dataSource === selectedDataSet?.dataSource;
	};

	return (
		<div className="data-sets-list">
			{items.map(item => (
				<div key={item.url} className={`data-set-link-wrapper ${isSelected(item) ? 'is-selected' : ''}`}>
					<button className="btn-hyperlink" onClick={() => onSelectDataSet(item)}>
						<span>
								<span>{item.label}</span>
							{item.openIssueCount > 0 && <span className="ms-2 open-issue-badge-wrapper"><Badge count={item.openIssueCount} color={'danger'} /></span>}
						</span>
					</button>
				</div>
			))}
			{(fdnyArchiveItems?.length > 0) && (
				<Collapse title={'FDNY (Archive)'} initCollapsed>
					{fdnyArchiveItems.map(item => (
						<div key={item.url} className={`data-set-link-wrapper ${isSelected(item) ? 'is-selected' : ''}`}>
							<button className="btn-hyperlink" onClick={() => onSelectDataSet(item)}>
								<span>
										<span>{item.label}</span>
									{item.openIssueCount > 0 && <span className="ms-2 open-issue-badge-wrapper"><Badge count={item.openIssueCount} color={'danger'} /></span>}
								</span>
							</button>
						</div>
					))}
				</Collapse>
			)}
		</div>
	);
}

export default DataSetsList;
