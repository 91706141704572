import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { useMediaPredicate } from 'react-media-hook';
import {
	MOBILE_SIZE,
	WIDE_SCREEN,
} from 'utils/mediaQueryUtils';

import OutboundLink from 'components/OutboundLink';
import Spinner from 'components/Spinner';
import { usePageTitle } from 'utils/pageTitle';
import { fetchFirstLoadTasks } from 'reducers/firstLoadTasksReducer';
import { checkAddressAsString } from 'reducers/addressReducer';
import { fetchZoningData } from 'reducers/zoningReducer';
import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';
import { CONTACT_PHONE, CONTACT_PHONE_FORMATTED, CONTACT_EMAIL, CONTACT_ADDRESS } from 'utils/contactInfo';

import OverviewToolbar from 'pages/_rd_overview/toolbar/OverviewToolbar';

import PenaltiesRefundsCardV2 from 'pages/_rd_overview/PenaltiesRefundsCardV2';
import VendorsCard from 'pages/_rd_overview/VendorsCard';
import SignageCard from 'pages/_rd_overview/SignageCard';
// import AICard from 'pages/_rd_overview/AICard';
import AlertsCard from 'pages/_rd_overview/AlertsCard';
import MapCard from 'pages/_rd_overview/mapCard/MapCard';
import BuildingInfoCard from 'pages/_rd_overview/propertyInfoCard/BuildingInfoCard';
import DataSetsCardVertical from 'pages/_rd_overview/dataSetsCard/DataSetsCardVertical';
import DataSetsCardMobile from 'pages/_rd_overview/dataSetsCard/DataSetsCardMobile';
 
import './PropertyOverview.css';

const PropertyOverview = (props) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const isLandingPage = location.pathname === '/';
	let { address } = useParams();
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	let isWideScreen = useMediaPredicate(WIDE_SCREEN);

	let {
		isLoading,
		propertyData,
		propertyId,
		zoningData,
	} = useSelector(state => ({
		isLoading: state.property.isLoading || state.address.isLoading || state.zoning.isLoading,
		propertyData: state.property.propertyData,
		propertyId: state.property.propertyData?.id,
		zoningData: state.zoning.data || {},
	}));

	usePageTitle(null, propertyData);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (!isLandingPage) {
			let decodedAddress = decodeURIComponent(address);
			if (!isLoading && decodedAddress !== propertyData?.address) {
				dispatch(checkAddressAsString(decodedAddress));
			}
		} else if (isLandingPage && !isLoading) {
			dispatch(openModal(MODAL_TYPES.GET_STARTED));
		}
	}, [address, isLandingPage]);

	useEffect(() => {
		if (!isLandingPage) {
			if (propertyId) {
				dispatch(fetchFirstLoadTasks(propertyId));
				dispatch(fetchZoningData(propertyId));
			}
		}
	}, [propertyId, isLandingPage]);

	let desktopCardLayout = (
		<div className="overview-row">
			<div className="overview-card-wrapper growable-card-wrapper info-card-wrapper">
				<BuildingInfoCard propertyData={propertyData} zoningData={zoningData} />
			</div>
			<div className="overview-card-wrapper image-card-wrapper">
				<MapCard
					propertyData={propertyData}
					zoningData={zoningData}
				/>
			</div>
		</div>
	);

	let mobileCardLayout = (
		<div className="overview-row">
			<div className="overview-card-wrapper image-card-wrapper">
				<MapCard
					propertyData={propertyData}
					zoningData={zoningData}
				/>
			</div>
			<div className="overview-card-wrapper growable-card-wrapper info-card-wrapper">
				<BuildingInfoCard propertyData={propertyData} zoningData={zoningData} />
			</div>
		</div>
	);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<div className="rd-overview-page">
			<OverviewToolbar />
		
			<div className={`rd-overview-content-wrapper ${isWideScreen ? 'is-widescreen' : ''}`}>
				<div className="overview-left">
					{isMobile ? mobileCardLayout : desktopCardLayout}
					<div className="overview-row">
						<div className="overview-card-wrapper growable-card-wrapper">
							{/*<DataSetsCard />*/}
							{isMobile ? <DataSetsCardMobile /> : <DataSetsCardVertical />}
						</div>
					</div>
{/*						{!isWideScreen &&
						<div className="overview-row">
							<div className="overview-card-wrapper growable-card-wrapper"><CalendarWeek /></div>
						</div>
					}*/}
{/*						<div className="overview-row">
						<div className="overview-card-wrapper growable-card-wrapper"><PenaltiesRefundsCard /></div>
					</div>*/}
					{!isWideScreen && (
						<div className="overview-row four-cards-row">
							<PenaltiesRefundsCardV2 />
							<AlertsCard />
							<VendorsCard />
							<SignageCard />
						</div>
					)}
				</div>
				{isWideScreen && (
					<div className="overview-right h-100">
						<div className="overview-row h-100">
							<div className="overview-card-wrapper">
								<AlertsCard />
								<PenaltiesRefundsCardV2 />
								<VendorsCard />
								<SignageCard />
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="d-flex justify-content-between">
				<div className="m-2">
					<OutboundLink className="m-2" url={'https://maps.app.goo.gl/3S2kgXWk48vREADu9'} target={"_blank"} rel="noreferrer"><span>{CONTACT_ADDRESS}</span></OutboundLink> | 
					<OutboundLink className="m-2" url={`tel:${CONTACT_PHONE}`}><span>{CONTACT_PHONE_FORMATTED}</span></OutboundLink> | 
					<OutboundLink className="m-2" url={`mailto:${CONTACT_EMAIL}`}><span>{CONTACT_EMAIL}</span></OutboundLink>
				</div>
				<div className="m-2"><Link to="/use-policy" className="m-2">Acceptable Use Policy</Link> | <Link to="/disclaimer" className="m-2">Disclaimer</Link></div>
			</div>


			
		</div>
	);
}

export default PropertyOverview;
