import { useState } from 'react';

import './Collapse.css';

function Collapse({ title, children, initCollapsed, hideChevron }) {
	const [isCollapsed, setIsCollapsed] = useState(initCollapsed || false);

	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed);
	};

	return (
		<div className="vio-collapse-container">
			<div className="vio-collapse-header">
				<button className="vio-collapse-button btn btn-hyperlink" onClick={toggleCollapse}>
					<span className="vio-collapse-button-text">{title}</span>
					{!hideChevron && <span className="vio-collapse-button-icon"><i className={`bi bi-chevron-${isCollapsed ? 'right' : 'down'}`} /></span>}
				</button>
			</div>
			{!isCollapsed && (
				<div className="vio-collapse-body">
					{children}
				</div>
			)}
		</div>
	);
}

export default Collapse;
