import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { usePageTitle } from 'utils/pageTitle';
import { bulkDocumentRequest, clearDocumentRequestError, REQUEST_DOCUMENT_SUCCESS } from 'reducers/documentRequestReducer';
import { clearCart } from 'reducers/cartReducer';
import { isValidEmail } from 'utils/validators';

import SpinnerOverlay from 'components/SpinnerOverlay';
import Alert from 'components/Alert';

// import './CheckoutPage.css';
import './CartPage.css';


const CheckoutPage = (props) => {
	usePageTitle('Checkout');
	const dispatch = useDispatch();

	const {
		cartItems,
		userData,
	} = useSelector(state => ({
		cartItems: state.cart.items || [],
		userData: state.user.userData,
	}));

	useEffect(() => {
		if (userData?.email) {
			setEmail(userData.email);
			setIsEmailValid(isValidEmail(userData.email));
		}
		if (userData?.phone) {
			setPhone(userData.phone);
		}
	}, [userData]);

	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [isEmailValid, setIsEmailValid] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	// const [sentToEmail, setSentToEmail] = useState('');

	const onChangeEmail = (emailString) => {
		setEmail(emailString);
		setIsEmailValid(isValidEmail(emailString));
	}

	const onConfirmCheckout = () => {
		if (isValidEmail && cartItems?.length) {
			setIsSuccess(false);
			setIsError(false);
			setIsSubmitting(true);
			// setSentToEmail(email);

			// let promises = cartItems.map(item => dispatch(requestDocumentByEmail({
			// 	email,
			// 	phone,
			// 	name,
			// 	propertyId: item.propertyId,
			// 	documentName: item.documentName,
			// 	extra: item.extra || null,
			// })));


			// Promise.all(promises).then((values) => {
			// 	setIsSubmitting(false);
			// 	let someSucceeded = values.some(value => value.type === REQUEST_DOCUMENT_SUCCESS);
			// 	if (someSucceeded) {
			// 		setIsSuccess(true);
			// 		dispatch(clearCart());
			// 		dispatch(clearDocumentRequestError());
			// 	} else {
			// 		setIsError(true);
			// 	}
			// });

			let documentNames = cartItems.map(item => item.documentName);

			dispatch(bulkDocumentRequest({
				email,
				phone,
				name,
				propertyId: cartItems[0]?.propertyId,
				documentNames
			})).then((result) => {
				setIsSubmitting(false);
				if (result.type === REQUEST_DOCUMENT_SUCCESS) {
					setIsSuccess(true);
					dispatch(clearCart());
					dispatch(clearDocumentRequestError());
				} else {
					setIsError(true);
				}
			});
		}
	}

	if (isSuccess) {
		return (
			<div className="checkout-page">
				<div className="card m-4">
					<div className="card-header">
						Confirmation
					</div>
					<div className="card-body">
						<Alert color="success">Your order has been received!</Alert>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="checkout-page">
			<SpinnerOverlay isLoading={isSubmitting} text="Checking out..." />
			<div className="card m-4">
				<div className="card-header">
					Checkout
				</div>
				<div className="card-body">
					{isError && <Alert color="danger">Something went wrong. Your order was not able to be submitted.</Alert>}

					<div>
						<div className="form-group">
							<label>Email Address</label>
							<input
								type="email"
								placeholder="Email"
								className={`form-control email-input ${isEmailValid ? 'valid' : 'invalid'}`}
								required
								value={email}
								onChange={(ev) => onChangeEmail(ev.target.value)}
								autoFocus
							/>
						</div>
						<div className="form-group">
							<label>Name</label>
							<input
								type="text"
								className={`form-control ${name?.length ? 'valid' : 'invalid'}`}
								required
								value={name}
								onChange={ev => setName(ev.target.value)}
								placeholder="Your name"
							/>
						</div>
						<div className="form-group">
							<label>Phone Number</label>
							<input
								type="text"
								className={`form-control ${phone?.length ? 'valid' : 'invalid'}`}
								required
								value={phone}
								onChange={ev => setPhone(ev.target.value)}
								placeholder="(555)-555-5555"
							/>
						</div>
					</div>

					<div className="cart-items">
						<div>A request will be sent for the following documents:</div>
						{cartItems.map(item => (
							<div key={item.cartItemId} className="m-2 cart-item-row">
								<div><strong>{item.documentName}</strong></div>
								{item.extra && <div>{item.extra}</div>}
								<div>{item.address}</div>
							</div>
						))}
					</div>

					<div className="cart-buttons-container">
						<button
							className="btn btn-primary"
							disabled={!(email && isEmailValid) || !phone || !name}
							onClick={() => onConfirmCheckout()}
						>
							Place order
						</button>
					</div>
					<div className="my-4">
						<Link to="/cart">Back to cart</Link>
					</div>
				</div>
			</div>
		</div>
	);	
}

export default CheckoutPage;
