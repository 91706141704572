import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { submitMissingProperty } from 'reducers/missingPropertyReducer';
import { isValidEmail } from 'utils/validators';

import Alert from 'view/components/Alert';
import Spinner from 'view/components/Spinner';

import StandardModal from 'modals/StandardModal';

const MissingPropertyReportModal = ({ options }) => {
	const dispatch = useDispatch();
	const { missingAddress, type } = options;
	const {
		isSubmitting,
		hasError,
		userData,
	} = useSelector(state => ({
		isSubmitting: state.missingProperty.isSubmitting,
		hasError: !!state.missingProperty.error,
		userData: state.user.userData,
	}));

	useEffect(() => {
		if (userData?.email) {
			setEmail(userData.email);
			setIsEmailValid(isValidEmail(userData.email));
		}
		if (userData?.name) {
			setName(userData.name);
		}
	}, [userData]);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (missingAddress) {
			setAddress(missingAddress);
		}
	}, []);

	const [email, setEmail] = useState('');
	const [isEmailValid, setIsEmailValid] = useState('');
	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [comment, setComment] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	// const [sentToEmail, setSentToEmail] = useState('');

	const onChangeEmail = (emailString) => {
		setEmail(emailString);
		setIsEmailValid(isValidEmail(emailString));
	}

	const onSubmit = () => {
		if (isValidEmail) {
			setIsSuccess(false);
			// setSentToEmail(email)
			let data = { address, comment, email, name, type };
			dispatch(submitMissingProperty(data)).then((res) => {
				if (res?.type === "MISSING_PROPERTY_SUCCESS") {
					setIsSuccess(true);	
				}
			});
		}
	}

	const reqForm = (
		<div>
			<div className="form-group">
				<label>Name</label>
				<input
					type="text"
					className={`form-control ${name?.length ? 'valid' : 'invalid'}`}
					required
					value={name}
					onChange={ev => setName(ev.target.value)}
					placeholder="Your name"
				/>
			</div>
			<div className="form-group">
				<label>Email Address</label>
				<input
					type="email"
					placeholder="Email"
					className={`form-control email-input ${isEmailValid ? 'valid' : 'invalid'}`}
					required
					value={email}
					onChange={(ev) => onChangeEmail(ev.target.value)}
					autoFocus
				/>
			</div>
			<div className="form-group">
				<label>Address</label>
				<input
					type="text"
					required
					className="form-control"
					value={address}
					onChange={ev => setAddress(ev.target.value)}
					placeholder="Address"
				/>
			</div>
			<div className="form-group">
				<label>Comments</label>
				<textarea
					className="form-control"
					value={comment}
					onChange={ev => setComment(ev.target.value)}
					placeholder="Comments"
				/>
			</div>
		</div>
	);

	const errorAlert = (
		<Alert color="danger">
			<p><strong>Oops, looks like something went wrong</strong></p>
			<div>Your request could not be submitted</div>
		</Alert>
	);

	const successAlert = (
		<Alert color="success">
			<p><strong>Success!</strong></p>
			<div>{`Your report has been submitted successfully`}</div> 
		</Alert>
	);

	let message = "Hey there! It looks like we don’t have your property in our database yet. Let us help you out!";
	let prompt = "Please fill out the form below, and we’ll investigate and get back to you as soon as possible.";
	if (type === 'Outside NYC') {
		message = "Oops! We currently support only properties located within NYC. If you’d like us to expand our services to your area, let us know!";
		prompt = "Fill out the form below, and we’ll reach out to you once we start supporting properties in your region.";
	}


	return (
		<StandardModal
			title={`Missing Property`}
			size="lg"
			closeOnOutsideClick
			onConfirm={onSubmit}
			disableConfirm={!address || !email || !name || !isEmailValid || isSubmitting}
			disableClose={isSubmitting}
			confirmText="Submit"
			closeLabel="Cancel"
		>
			<div className="docuemnt-request-modal-content">
				{isSubmitting && <Spinner />}
				{hasError && errorAlert}
				{isSuccess && successAlert}
				<div className="mb-3">{message}</div>
				<div className="mb-3">{prompt}</div>
				{reqForm}
			</div>
		</StandardModal>
	);
};

export default MissingPropertyReportModal;
