import AddressSearch from 'components/addressSearch/AddressSearch';
import { useSelector, useDispatch } from 'react-redux';
import { usePageTitle } from 'utils/pageTitle';


import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import './PropertyNotFound.css';


const nycCityNames = [
	'New York, NY',
	'Brooklyn, NY',
	'Staten Island, NY',
	'Bronx, NY',
	'The Bronx, NY',
	'Manhattan, NY',
	'Queens, NY',
];

const FORM_TYPES = {
	OUTSIDE_NYC: 'Outside NYC',
	NOT_FOUND: 'Not Found',
}

const isAddressInNYC = (address) => {
	let ret = false;
	nycCityNames.forEach(cityName => {
		if (address.includes(cityName)) {
			ret = true;
		}
	});
	return ret;
};

const PropertyNotFound = (props) => {
	const dispatch = useDispatch();
	usePageTitle('Not Found');
	const {
		query,
	} = useSelector(state => ({
		query: state.router.location?.query,
	}));
	let address = decodeURIComponent(query?.address);

	const openMissingPropertyModal = () => {
		let isInNYC = isAddressInNYC(address);
		let type = FORM_TYPES.NOT_FOUND;
		if (!isInNYC) {
			type = FORM_TYPES.OUTSIDE_NYC;
		}
		dispatch(openModal(MODAL_TYPES.MISSING_PROPERTY_REPORT, { missingAddress: address, type }))
	}

	return (
		<div className="property-not-found-page">
			<div className="property-not-found-page-content">
				<h1 className="text-center"><i className="bi bi-emoji-frown" /></h1>
				{query?.address ? (
					<div>
						<h4 className="text-center">We couldn't find</h4>
						<h2 className="text-center">{address}</h2>
					</div>
					) : (
					<h2 className="text-center">We couldn't find the property you're looking for</h2>
				)}
				<p className="text-center">Try searching for another property.</p>

				<div className="address-search-wrapper">
					<AddressSearch
						addressSearchLabel="Search by Address"
						blockAndLotSearchLabel="Search by Block and Lot"
					/>
				</div>

				<div className="d-flex justify-content-center my-4">
					<button className="btn btn-secondary" onClick={openMissingPropertyModal}>
						Report missing property
					</button>
				</div>
			</div>
		</div>
	);
};

export default PropertyNotFound;
